import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  IconButton,
  Box,
  Flex,
  VStack,
  HStack,
  useColorModeValue,
  CloseButton,
  Slide,
  Divider,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import {
  TrophyIcon,
  RocketLaunchIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { PiHandCoins } from "react-icons/pi";
import { HiOutlineWrench } from "react-icons/hi2";
import logo_dark from "assets/images/logo.png";
import logo_dark_inline from "assets/images/logo_inline_dark.svg";
import logo_light_inline from "assets/images/logo_inline_light.svg";
import ThemeSwitcher from "../ThemeSwitcher";
import SocialMedia from "../SocialMedia";
import Link from "ui/components/Link";
import { Link as ReactRouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Flex direction="column" pl={"1em"}>
      <Box w={"2em"} onClick={toggleMenu}>
        <GiHamburgerMenu
          style={{ color: useColorModeValue("#1F1F1F","white") }}
          size={20}
        />
      </Box>

      <Slide direction="left" in={isOpen} style={{ zIndex: 10 }}>
        <Box
          position="fixed"
          top="0"
          left="0"
          bg={useColorModeValue("white", "#1F1F1F")}
          width="100vw"
          height="100vh"
          p={4}
        >
          <VStack spacing={4} alignItems={"flex-start"} pl={"2em"}>
            <CloseButton alignSelf="flex-end" onClick={toggleMenu} />
            <HStack>
              <Box minW={"12.5em"}>
                <Flex
                  as={ReactRouterLink}
                  to="/"
                  onClick={() => {
                    toggleMenu();
                  }}
                  alignItems={"center"}
                  mb={"5em"}
                  gap={"1rem"}
                >
                  <Image src={logo_dark} w={"5em"}></Image>
                  <Heading
                    fontFamily={"main"}
                    fontWeight={"900"}
                    letterSpacing={"0.05em"}
                  >
                    PEAKDEFI
                  </Heading>
                </Flex>

                <Link
                  as={ReactRouterLink}
                  to="/"
                  onClick={() => {
                    toggleMenu();
                  }}
                  variant={location.pathname === "/" ? "active" : undefined}
                  fontSize={"1.25em"}
                  mb={"1.25em"}
                  leftIcon={<RocketLaunchIcon />}
                >
                  Promote to Earn
                </Link>
                <Link
                  as={ReactRouterLink}
                  to="/rewards"
                  onClick={() => {
                    toggleMenu();
                  }}
                  fontSize={"1.25em"}
                  mb={"1.25em"}
                  variant={location.pathname === "/rewards" ? "active" : ""}
                  leftIcon={<PiHandCoins />}
                >
                  Rewards
                </Link>
                <Link
                  as={ReactRouterLink}
                  to="/set-up-referrals"
                  onClick={() => {
                    toggleMenu();
                  }}
                  fontSize={"1.25em"}
                  mb={"1.25em"}
                  leftIcon={<HiOutlineWrench />}
                  variant={
                    location.pathname === "/set-up-referrals"
                      ? "active"
                      : undefined
                  }
                >
                  Set up Campaign
                </Link>
                <Link
                  as={ReactRouterLink}
                  to="/leaderboard"
                  onClick={() => {
                    toggleMenu();
                  }}
                  variant={
                    location.pathname === "/leaderboard" ? "active" : undefined
                  }
                  fontSize={"1.25em"}
                  mb={"1.25em"}
                  leftIcon={<TrophyIcon />}
                >
                  leaderboard
                </Link>
                {/* </Stack> */}

                <Divider />
                <Flex
                  as={"a"}
                  href={"https://launchpad.peakdefi.com/"}
                  py={"1.25em"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  target="_blank"
                >
                  <Image
                    src={useColorModeValue(logo_dark_inline, logo_light_inline)}
                    h={"1.7em"}
                  />
                  <ArrowUpRightIcon
                    height={"1.1em"}
                    color={useColorModeValue("#0D0D0D", "#FFFFFF")}
                    opacity={"0.5"}
                  />
                </Flex>
                <Divider />

                <Link
                  as={ReactRouterLink}
                  to="/FAQ"
                  variant={location.pathname === "/FAQ" ? "active" : undefined}
                  fontSize={"1.25em"}
                  mt={"2.5em"}
                  leftIcon={<QuestionMarkCircleIcon />}
                >
                  FAQ
                </Link>

                <Flex
                  justifyContent={"space-between"}
                  py={"5em"}
                  alignItems={"center"}
                >
                  <Text variant={"navbar"} fontSize={"1.25em"}>
                    Theme
                  </Text>
                  <ThemeSwitcher />
                </Flex>

                <SocialMedia />
              </Box>
            </HStack>
          </VStack>
        </Box>
      </Slide>
    </Flex>
  );
};

export default MobileMenu;
