import {
  Center,
  Flex,
  useColorModeValue,
  Collapse,
  Box,
  Image,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { useNetwork, useAccount, useSwitchNetwork } from "wagmi";
import { SlArrowDown } from "react-icons/sl";
import { getImageWithId, networksList } from "./helpers";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { useConnect } from "wagmi";

interface ChangeNetworksProps {}

const ChangeNetworks: FunctionComponent<ChangeNetworksProps> = () => {
  const [showCollapse, setShowCollapse] = useState(false);
  const { chain } = useNetwork();
  const { address } = useAccount();
  const { switchNetworkAsync } = useSwitchNetwork();

  const metaMaskConnect = useConnect({
    connector: new MetaMaskConnector(),
  });
  const onClick = () => {
    setShowCollapse(!showCollapse);
  };

  const changeNetwork = (item: any) => {
    if (!address) {
      metaMaskConnect.connect();
    }
    if (switchNetworkAsync) {
      switchNetworkAsync(item.chainId);
    } else {
    }
  };

  return (
    <Box>
      <Flex
        h={"2.5em"}
        border={"1px solid"}
        borderColor={useColorModeValue("#E0E0E0", "rgba(255, 255, 255, 0.20)")}
        borderRadius={"2.5em"}
        alignItems={"center"}
        pr={{ base: "0", sm: "0.5rem" }}
        cursor={"pointer"}
        onClick={() => {
          if (window.innerWidth > 740) {
            onClick();
          }
        }}
      >
        <Center
          h={"2.4em"}
          w={"2.4em"}
          bg={useColorModeValue("#E0E0E0", "rgba(255, 255, 255, 0.20)")}
          borderRadius={"50%"}
        >
          <Image
            src={getImageWithId(chain)}
            w={"5em"}
            borderRadius={"50%"}
          ></Image>
        </Center>

        <Center p={"0.5em"} display={{ base: "none", sm: "flex" }}>
          <SlArrowDown
            style={{
              transform: `rotate( ${showCollapse ? 180 : 0}deg)`,
              transition: "transform 0.3s ease",
            }}
          />
        </Center>
      </Flex>
      <Collapse
        in={showCollapse}
        style={{
          zIndex: 100,
          position: "absolute",
          opacity: 1,
          background: useColorModeValue("#F5F5F5", "#1A1A1A"),
          borderRadius: "0.25em",
          marginTop: "0.25em",
        }}
      >
        <Box p={"0.5em"}>
          {networksList.map((item, index) => {
            return (
              <Flex onClick={() => changeNetwork(item)}>
                <Flex>
                  <Center
                    p={"0.5em"}
                    borderBottom={
                      index + 1 === networksList.length ? "0px" : "1px"
                    }
                    style={{
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      borderColor: useColorModeValue(
                        "#E0E0E0",
                        "rgba(255, 255, 255, 0.20)"
                      ),
                    }}
                    _hover={{
                      opacity: 0.7,
                      cursor: "pointer",
                    }}
                  >
                    <Image
                      src={item.image}
                      w={"1.5em"}
                      borderRadius={"10%"}
                    ></Image>
                    <Text
                      fontFamily="secondary"
                      fontSize={"1em"}
                      fontWeight={"500"}
                      pl={"1em"}
                    >
                      {item.name}
                    </Text>
                  </Center>
                </Flex>
              </Flex>
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ChangeNetworks;
